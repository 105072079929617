import React from "react"
import styled from "@emotion/styled"
import { FieldLabel } from "../fields"
import CalculateTextBox from "../CalculateTextBox/CalculateTextBox"

const MobileNumber = () => {
  return (
    <FieldContainer>
      <FieldLabel
        labelFor="telephoneNumber"
        title="Mobilnummer"
        descText=""
        align="center"
      />

      <CalculateTextBox
        fieldName="telephoneNumber"
        placeHolder="Mobilnummer"
        fieldType="tel"
        maxLength={16}
      />
    </FieldContainer>
  )
}
export default MobileNumber

const FieldContainer = styled.div``
