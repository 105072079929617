import React from "react"
import styled from "@emotion/styled"

const ButtonLoader = () => {
  return (
    <Loading>
      <svg
        width="20"
        height="20"
        viewBox="0 0 44 46"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-16.000000, -15.000000)" fill="#ffffff">
            <g>
              <path
                d="M37.8928259,22.6666667 C31.2374565,22.6666667 25.5630371,26.9090167 23.4450565,32.8490222 C22.7340371,34.8431222 20.5410765,35.8832972 18.5469573,35.1722778 C16.5528318,34.4612583 15.5126766,32.2682722 16.2237018,30.2741722 C19.3959384,21.3773889 27.8947259,15 37.8928259,15 C44.3560176,15 50.2021704,17.670875 54.3759676,21.9591611 C55.8384482,23.4618278 55.8222204,25.8607278 54.3395509,27.3433972 L36.6004815,45.0824667 C35.1035009,46.5794472 32.6763621,46.5794472 31.1793815,45.0824667 C29.6823371,43.5854222 29.6823371,41.1582833 31.1793815,39.6613028 L45.9127982,24.9278861 C43.5791287,23.4924306 40.8340148,22.6666667 37.8928259,22.6666667 Z M18.5469573,40.8277222 C20.5410765,40.1167028 22.7340371,41.1568778 23.4450565,43.1509778 C25.5630371,49.0909833 31.2374565,53.3333333 37.8928259,53.3333333 C44.5482593,53.3333333 50.2226787,49.0909833 52.3406593,43.1509778 C53.0516787,41.1568778 55.2446009,40.1167028 57.2387648,40.8277222 C59.2328648,41.5387417 60.2730398,43.7317278 59.5620204,45.7258278 C56.3897454,54.6226111 47.8909898,61 37.8928259,61 C27.8947259,61 19.3959384,54.6226111 16.2237018,45.7258278 C15.5126766,43.7317278 16.5528318,41.5387417 18.5469573,40.8277222 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </Loading>
  )
}
export default ButtonLoader

const Loading = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 30px;
    margin: 0;

    animation: rotate 1.5s linear infinite;

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`
